<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="福建福州" name="first">
      <fuzhou-list></fuzhou-list>
    </el-tab-pane>
    <el-tab-pane label="福建福州快科" name="second">
      <fuzhou-kuaike-list></fuzhou-kuaike-list>
    </el-tab-pane>
    <el-tab-pane label="福建莆田" name="three">
      <putian-list></putian-list>
    </el-tab-pane>
    <el-tab-pane label="福建龙岩" name="four">
      <longyan-list></longyan-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import FuzhouList from "@/views/passport/fujian/FuzhouList";
  import FuzhouKuaikeList from "@/views/passport/fujian/FuzhouKuaikeList";
  import LongyanList from "@/views/passport/fujian/LongyanList";
  import PutianList from "@/views/passport/fujian/PutianList";

  export default {
    components: {FuzhouList, FuzhouKuaikeList,LongyanList,PutianList},
    data() {
      return {
        activeName: "first",
      };
    },
    methods: {},
  };
</script>

<style scoped>

</style>
